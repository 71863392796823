
import { defineComponent } from "vue";
import { calendarFormat} from "@/utils/dateFormat";

export default defineComponent({
  name: "InvoiceItem",
  props: {
    accounts: Object,
  },
  
  setup() {
    return {
      calendarFormat,
    };
  }
});
